import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import usersApi from '@/api/common/users';
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
import moment from "moment";
import { pickerOptions } from '@/constant/config';
export default {
    extends: baseComponent,
    components: {
        detailUser: lazyLoadComponent({
            componentFactory: () => import("@/views/users/DetailUserInfo"),
            loading: SkeletonBox,
        }),
        detailUserNote: lazyLoadComponent({
            componentFactory: () => import("@/views/users/userNoteList"),
            loading: SkeletonBox,
        }),
        sameIPUserList: lazyLoadComponent({
            componentFactory: () => import("@/views/users/SameIPUserList"),
            loading: SkeletonBox,
        }),
        comments: lazyLoadComponent({
            componentFactory: () => import("@/components/Comments"),
            loading: SkeletonBox,
        }),
        classifyTagManagement: lazyLoadComponent({
            componentFactory: () => import("@/views/users/ClassifyTagManagement"),
            loading: SkeletonBox,
        }),
        // templateList: lazyLoadComponent({
        //     componentFactory: () => import("@/views/users/_UserListTemplate"),
        //     loading: SkeletonBox,
        // }),
        templateList: lazyLoadComponent({
            componentFactory: () => import("@/views/users/_UserList"),
            loading: SkeletonBox,
        })
    },
    watch: {
        //$route(to, from) {
        //    if (this.$route.query.view) {
        //        if (this.$route.query.view === 'low-balance') {
        //            this.onFilterUserLowBalance();
        //        }
        //    }
        //},
        //lowBalanceUser(newCount, oldCount) {
        //    if (this.userLowBalance) {
        //        this.onFilterUserLowBalance();
        //    }
        //}
    },
    data() {
        return {
            debounceFnc: null,
            debounceFilterFnc: null,
            pickerOptions: pickerOptions,
            viewTab: "",
            nationality: "",
            elements: {
                userSourceFilterList: [],
                queryAction: "",
                querySearch: "",
                filterItem: {
                    teamSelected: "",
                    acceptTimeSelected: "",
                    seedingSource: "",
                    classify: [],
                    isDeactived: "",
                },
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: null,
                    directionSort: "desc"
                }
            },
            toogleFilter: false,
            userLowBalance: false,
            topRecharge: false,
            detailUserNote: null,
            elementDetail: null,
            elementDetailSameIp: null,

            selectItemToggle: false,
            selectionModel: {
                isCheckAll: false,
                itemsSelected: [],
            },
            isSortByWalletDesc: false
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            staffsInfo: state => state.staffs,
            lowBalanceUser: state => state.lowBalanceUser,
            userTagClassifyList: state => state.common.userClassifyAll
        }),
        regionList() {
            return Object.keys(this.$countryList).map(x => {
                return { id: x, text: this.$countryList[x] }
            });
        },
        staffList() {
            return this.staffsInfo.filter(x => x.mainDepartment === 'OPERATING_STAFF').map(x => {
                return { id: x.email, text: x.fullName }
            });
        },
        sourceList() {
            return this.elements.userSourceFilterList.map(x => {
                return { id: x, text: x }
            });
        },
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 20,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: null,
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                filterItem: Object.assign(this.elements.filterItem, Object.assign({ appCode: this.viewTab }, {
                    userLowBalance: this.userLowBalance,
                    topRecharge: this.topRecharge,
                    isSortByWalletDesc: this.isSortByWalletDesc,
                }))
            };
        },
    },
    created() {
        this.getUserSource();
        this.getClassify();
        if (this.$route.query.view) {
            if (this.$route.query.view === 'low-balance') {
                this.onFilterUserLowBalance();
            }
            else this.getAllResult(1);
        }
        else this.getAllResult(1);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalCheckLowBalance);
    },
    methods: {
        toogleSelectionMode() {
            this.selectItemToggle = !this.selectItemToggle;
            this.selectionModel = {
                isCheckAll: false,
                itemsSelected: [],
            };
        },
        toogleSortByAccountBalance() {
            this.isSortByWalletDesc = !this.isSortByWalletDesc;
            this.pageClickHandle(1);
        },
        getClassify() {
            this.$store.dispatch("common/getAllClassify");
        },
        getUserSource() {
            usersApi.getUserSource().then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.userSourceFilterList = response.data.data;
                }
            }).catch(error => {
                console.error(error);
            });
        },
        getElementsList(pageNumber) {
            this.showLoading();
            this.elements.data = [];
            usersApi.getElementsList(pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    response.data.data.data.forEach((element) => {
                        try {
                            if (element.registeredHistory) {
                                element.registeredHistory = JSON.parse(element.registeredHistory).sort(function (a, b) {
                                    return parseFloat(moment(b.createdAt).valueOf()) - parseFloat(moment(a.createdAt).valueOf());
                                });
                            }
                        } catch (error) {
                            console.log("🚀 ~ file: usersList.js ~ line 169 ~ response.data.data.data.forEach ~ error", error)
                        }
                    });
                    this.elements.data = response.data.data.data;
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.hideLoading();
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);

            });
        },

        getAllResult(pageNumber) {
            this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.elements.queryAction = "";
            this.elements.filterItem = {
                classify: this.elements.filterItem.classify,
                acceptTimeSelected: this.elements.filterItem.acceptTimeSelected
            };
            this.getElementsList(pageNumber);
        },
        getSearchQueryResult(pageNumber) {
            this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.elements.queryAction = "search";
            this.elements.filterItem = {
                classify: this.elements.filterItem.classify,
                acceptTimeSelected: this.elements.filterItem.acceptTimeSelected
            };
            this.getElementsList(pageNumber);
        },
        getFilterQueryResult(pageNumber) {
            this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.elements.queryAction = "filter";
            this.getElementsList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            switch (this.elements.queryAction) {
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                default:
                    this.getAllResult(pageNumber);
                    break;
            }
        },
        refreshClick() {
            this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.elements.querySearch = "";
            this.elements.filterItem = {};
            if (this.viewTab === "") this.getAllResult(1);
            else {
                this.elements.filterItem = {};
                this.clearExtraFilter();
                this.getFilterQueryResult(1);
            }
        },
        onSearchInput(isDebounce) {
            this.clearExtraFilter();
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        onFilterInput(isDebounce) {
            if (isDebounce) {
                this.clearExtraFilter();
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        clearExtraFilter() {
            this.userLowBalance = false;
            this.topRecharge = false;
        },
        onFilterTopRecharge() {
            //this.viewTab = "";
            //this.nationality = "";
            this.elements.filterItem = {
                classify: this.elements.filterItem.classify,
                acceptTimeSelected: this.elements.filterItem.acceptTimeSelected
            };
            this.topRecharge = true;//!this.topRecharge;
            if (this.topRecharge) this.userLowBalance = false;
            this.onFilterInput(false);
        },
        onFilterUserLowBalance() {
            //this.viewTab = "";
            //this.nationality = "";
            this.elements.filterItem = {
                classify: this.elements.filterItem.classify,
                acceptTimeSelected: this.elements.filterItem.acceptTimeSelected
            };
            this.userLowBalance = true;//!this.userLowBalance;
            if (this.userLowBalance) this.topRecharge = false;
            this.onFilterInput(false);
        },
        onFilterTodayRegister() {
            //this.toogleFilter = true;
            //this.viewTab = "";
            this.clearExtraFilter();
            let startDate = moment().startOf('day').format('DD/MM/YYYY');
            let endDate = moment().startOf('day').add(1, 'days').format('DD/MM/YYYY');
            this.elements.filterItem.createdFilter = [startDate, endDate];
            this.getFilterQueryResult(1);
        },
        viewDetailInfo(userItem) {
            this.$set(this, "elementDetail", userItem);
            this.$nextTick(() => {
                $("#detailUserInfo").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailInfo() {
            $("#detailUserInfo").modal("hide");
            this.$set(this, "elementDetail", null);
        },
        viewDetailNote(userItem) {
            this.$set(this, "detailUserNote", userItem);
            this.$nextTick(() => {
                $("#detailUserNote").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        editDetailNote(userItem) {
            this.$set(this, "detailUserNote", userItem);
            this.$nextTick(() => {
                $("#detailUserNote").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailNote() {
            $("#detailUserNote").modal("hide");
            this.$set(this, "detailUserNote", null);
        },
        viewDetailSameIpUser(userItem) {
            this.$set(this, "elementDetailSameIp", userItem);
            this.$nextTick(() => {
                $("#detailSameIpUser").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailSameIpUser() {
            $("#detailSameIpUser").modal("hide");
            this.$set(this, "elementDetailSameIp", null);
        },
        viewClassifyTagsManagementModal() {
            this.$store.dispatch("common/getAllClassifyByStaff");
            this.$nextTick(() => {
                $("#classifyTagsManagementModal").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeClassifyTagsManagement() {
            $("#classifyTagsManagementModal").modal("hide");
            this.$store.dispatch("common/getAllClassifyByStaff");
            this.$refs.classifyTagManagement.closeModal();
        },
        newestCommentUpdated(noteContent) {

        },
        sendMailWarningLowBalance(userItem) {
            commonAct.showConfirm(`<div class="text-left">Send email warning low balance account for user <strong>${userItem.fullName}</strong> ?</div>`, () => {
                this.showLoading();
                usersApi.warningLowBalanceUser(userItem.id).then(response => {
                    if (response.data.result === 0) {
                        this.showSuccessToast(`<div class="text-left">Send email warning low balance account to user <strong>${userItem.fullName} (${userItem.email})</strong> has been succeeded</div>`);
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                    this.hideLoading();
                });
            });
        },
        updateSourceUser(userItem, staffSelected) {
            this.showLoading();
            usersApi.updateSourceUser(userItem.id, staffSelected.code).then(response => {
                if (response.data.result === 0) {
                    this.$set(userItem, 'seedingSource', staffSelected.code)
                    this.showSuccessToast(`<div class="text-left">Update source for user <strong>${userItem.fullName} (${userItem.email})</strong> has been succeeded</div>`);
                } else {
                    commonAct.showError(response.data.message);
                }
                this.hideLoading();
            }).catch(error => {
                console.error(error);
                commonAct.showError(error);
                this.hideLoading();
            });
        },
        updateUserTakeCare(userItem, staffSelected) {
            this.showLoading();
            let request = {
                userId: userItem.id,
                staffEmail: staffSelected.email,
                deviceInformation: JSON.stringify(this.getInformationClient()),
            }
            usersApi.updateUserTakeCare(request).then(response => {
                if (response.data.result === 0) {
                    this.$set(userItem, 'staffTakeCare', staffSelected.email)
                    this.showSuccessToast(`<div class="text-left">Update staff for user <strong>${userItem.fullName} (${userItem.email})</strong> has been succeeded</div>`);
                } else {
                    commonAct.showError(response.data.message);
                }
                this.hideLoading();
            }).catch(error => {
                console.error(error);
                commonAct.showError(error);
                this.hideLoading();
            });
        },
        acceptTakeCare(userItem) {
            commonAct.showConfirm(`Accept take care user <strong>${userItem.fullName}</strong> ?`, () => {
                this.showLoading();
                let request = {
                    userId: userItem.id,
                    deviceInformation: JSON.stringify(this.getInformationClient()),
                }
                usersApi.acceptTakeCare(request).then(response => {
                    if (response.data.result === 0) {
                        console.log("response.data.data", response.data.data);
                        this.$set(userItem, 'staffTakeCare', response.data.data)
                        this.showSuccessToast(`<div class="text-left">Accept take care for user <strong>${userItem.fullName} (${userItem.email})</strong> has been succeeded</div>`);
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                    this.hideLoading();
                });
            });
        },
        exportData() {
            let host = process.env.VUE_APP_API_HOST.slice(0, -1);
            //window.open(host + usersApi.exportUsersList(1, this.requestParam), '_blank');
            usersApi.exportUsersList(1, this.requestParam).then(response => {
                if (response.data && response.data.result === 0) {
                    this.$message({
                        message: 'Congrats, perform action succeed.',
                        type: 'success'
                    });
                }
            }).catch(error => {
                console.error(error);
            });
        },
        removeUser(targetItem) {
            commonAct.showConfirm(`Remove user <strong>${targetItem.fullName}</strong> ? This cannot be undone`, () => {
                this.showLoading();
                usersApi.removeUser(targetItem.id).then(response => {
                    if (response.data.result === 0) {
                        let foundedItemIndex = this.elements.data.findIndex(x => { return x.id === targetItem.id });
                        if (foundedItemIndex > -1) {
                            this.elements.data.splice(foundedItemIndex, 1);
                            this.getSearchQueryResult(this.elements.pagingItem.pageIndex);
                        }
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                    this.hideLoading();
                });
            });
        },
        setSeedingInfo(targetItem, seedingSource) {
            let request = {
                userId: targetItem.id,
                data: seedingSource,
                deviceInformation: JSON.stringify(this.getInformationClient()),
            };
            usersApi.setSeedingInfo(request)
                .then((response) => {
                    if (response.data && response.data.result === 0) {
                        this.$set(targetItem, "seedingSource", seedingSource);
                        this.showSuccessToast("User info has been updated");
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        updateUserTakeCareMultiple(staffSelected) {
            if (this.selectionModel.itemsSelected.length > 0) {
                this.$confirm(`This will permanently Assign selected users to staff <strong>${staffSelected.fullName}</strong>. Continue?`, 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    dangerouslyUseHTMLString: true,
                    type: 'warning'
                }).then(() => {
                    this.$confirm('Again, are you sure performce this action. Continue?', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        dangerouslyUseHTMLString: true,
                        type: 'warning'
                    }).then(() => {
                        this.showLoading();
                        let request = {
                            userIdSelected: this.selectionModel.itemsSelected,
                            staffEmail: staffSelected.email,
                            deviceInformation: JSON.stringify(this.getInformationClient()),
                        }
                        usersApi.updateUserTakeCareMultiple(request).then(response => {
                            if (response.data.result === 0) {
                                this.selectionModel.itemsSelected.forEach(x => {
                                    let foundIndexItem = this.elements.data.findIndex(itemX => itemX.id === x);
                                    if (foundIndexItem >= 0) {
                                        this.$set(this.elements.data[foundIndexItem], 'staffTakeCare', staffSelected.email);
                                    }
                                });
                                this.showSuccessToast("User info has been updated");
                                this.selectionModel.isCheckAll = false;
                                this.selectionModel.itemsSelected = [];
                                this.$forceUpdate();
                                this.pageClickHandle(1);
                            } else {
                                commonAct.showError(response.data.message);
                                this.hideLoading();
                            }
                        }).catch(error => {
                            console.error(error);
                            commonAct.showError(error);
                            this.hideLoading();
                        });
                    }).catch(() => { });
                }).catch(() => { });

                
            }
        },
        performAction(actionName, data) {
            this.listActions({ action: actionName, data: data });
        },
        listActions(param) {
            switch (param.action) {
                case 'removeUser':
                    this.removeUser(param.data);
                    break;
                case 'viewDetailInfo':
                    this.viewDetailInfo(param.data);
                    break;
                case 'sendMailWarningLowBalance':
                    this.sendMailWarningLowBalance(param.data);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
                case 'viewDetailSameIpUser':
                    this.viewDetailSameIpUser(param.data);
                    break;
                case 'acceptTakeCare':
                    this.acceptTakeCare(param.data);
                    break;
                case 'updateSourceUser':
                    this.updateSourceUser(param.data.item, param.data.source);
                    break;
                case 'updateUserTakeCare':
                    this.updateUserTakeCare(param.data.item, param.data.source);
                    break;
                case 'viewDetailNote':
                    this.viewDetailNote(param.data);
                    break;
                case 'editDetailNote':
                    this.editDetailNote(param.data);
                    break;
                case 'setSeedingInfo':
                    this.setSeedingInfo(param.data.userTarget, param.data.sourceTarget);
                    break;
                case 'updateUserTakeCareMultiple':
                    this.updateUserTakeCareMultiple(param.data.source);
                    break;
            }
        },
    }
}